.ButtonWrapper {
    display: flex;
    align-items: center;
    margin: 20px 0;
}

.ButtonCancel {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 125%;
    color: #6B799D;
    cursor: pointer;
    margin-right: 20px;
}

.ButtonSave {
    margin-right: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100px;
    height: 44px;
    background: #00A896;
    border-radius: 8px;
    color: #ffffff;
    font-weight: 600;
    font-size: 16px;
    padding: 0;
    transition: all .2s;
    border: none;
    cursor: pointer;
}

.MuiButton-contained {
    color: #ffffff;
    background-color: #e0e0e0;
}