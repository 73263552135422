.CollapseCard {
    height: auto;
    margin-bottom: 1rem;
    border-bottom: 1px solid #e6e6e6;
}

.CollapseCard .CollapseCard-Head {
    margin-bottom: 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.CollapseCard .CollapseCard-Head .wrap-arrow {
    margin-left: 1rem;
    cursor: pointer;
    transition: all 300ms ease;
}

.CollapseCard .CollapseCard-Body {
    transition: all 300ms ease;
    transform-origin: center top;
}

.CollapseCard .CollapseCard-Body p {
    color: #4f4f4f;
}

.CollapseCard.false {
    transition: all 300ms ease;
}

.CollapseCard.true {
    transition: all 300ms ease;
}

.CollapseCard.false > .CollapseCard-Body {
    transform: scaleY(0);
}

.CollapseCard.true > .CollapseCard-Body {
    transform: scaleY(1);
}
