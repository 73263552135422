.successButton {
  margin-right: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100px;
  height: 44px;
  background: #00a896;
  border-radius: 8px;
  color: #ffffff;
  font-weight: 600;
  font-size: 16px;
  padding: 0;
  transition: all 0.2s;
  border: none;
  cursor: pointer;
  margin-bottom: 20px;
}

.TitleText {
  font-style: normal;
  font-family: 'Open Sans';
  font-weight: 200;
  font-size: 2rem;
  line-height: 30px;
  color: #010A21;
}

.TableHeader {
  margin-left: 20px;
}

table {
  /* border-style: solid; */
  align-content: center;
  /* border: 2px solid; */
  margin: auto;
  width: 100%;
}
tr {
  /* border: 1px solid; */
  width: 100%;
}
th {
  align-content: center;
  /* border: 1px solid; */
  padding: 5px;
  /* text-align: center !important; */
}
td {
  /* border: 1px solid; */
  padding: 5px;
  /* text-align: center !important; */
}

th > *,
td > * {
  /* text-align: center !important; */
  font-weight: bold;
}
