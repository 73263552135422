@import '~antd/dist/antd.css';

@font-face {
  font-family: 'Open Sans';
  src: local('Open Sans Bold'), url("../fonts/open-sans/OpenSans-Bold.ttf") format('truetype');
}

.login {
  max-width: 340px;
  margin: 0 auto;
  padding: 1rem;
}

form {
  display: flex;
  flex-flow: column;
}

label {
  font-weight: 600;
}

input {
  padding: 8px;
  margin: 0.3rem 0 1rem;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.error {
  margin: 0.5rem 0 0;
  color: brown;
}

html,
body {
  padding: 0;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  background: #f5faff;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

.ant-layout-header {
  background: #ffff;
}

.ant-layout-footer {
  background: #f5faff;
}

.footer {
  width: 100%;
  height: 100px;
  border-top: 1px solid #eaeaea;
  display: flex;
  justify-content: center;
  align-items: center;
}

.footer img {
  margin-left: 0.5rem;
}

.footer a {
  display: flex;
  justify-content: center;
  align-items: center;
}
