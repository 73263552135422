
.Container {
    border-bottom: 1px solid #D7DCE8;
    padding-bottom: 10px;
}

.Export {
    font-style: normal;
    font-family: 'Open Sans';
    font-weight: 200;
    font-size: clamp(1rem, 2vw, 1.5rem);
    line-height: 30px;
    color: #010A21;
}

.Toolbar {
    width: 40px !important;
    height: 40px !important;
}
.IconToolbar {
    color: #ffffff !important;
}
