.loadingWrapper {
  position: relative;
  z-index: 1111;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.loadingWrapper__content {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 439px;
  height: 247px;
  background: #fff;
  border: 1px solid #e3e9f8;
  box-sizing: border-box;
  border-radius: 5px;
}
.Loading {
  display: flex;
  justify-content: center;
}
.Dot {
  position: relative;
  width: 1.5em;
  height: 1.5em;
  margin: 0.5em;
  border-radius: 50%;
}
.Dot::before {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  background: inherit;
  border-radius: inherit;
  animation: wave 2s ease-out infinite;
}
.Dot:nth-child(1) {
  background: #09a595;
}
.Dot:nth-child(1)::before {
  animation-delay: 0.2s;
}
.Dot:nth-child(2) {
  background: #0094a2;
}
.Dot:nth-child(2)::before {
  animation-delay: 0.4s;
}
.Dot:nth-child(3) {
  background: #047cae;
}
.Dot:nth-child(3)::before {
  animation-delay: 0.6s;
}
.Dot:nth-child(4) {
  background: #018192;
}
.Dot:nth-child(4)::before {
  animation-delay: 0.8s;
}
.Dot:nth-child(5) {
  background: #02985a;
}
.Dot:nth-child(5)::before {
  animation-delay: 1s;
}
@keyframes wave {
  50%, 75% {
    transform: scale(2);
  }
  80%, 100% {
    opacity: 0;
  }
}
