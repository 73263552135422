.LoyaltyFormWrapper {
    display: flex;
    flex-direction: row;
    margin-top: 8px;
}
.listField {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 529px;
    margin-right: 32px;
}
.ItemField {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
    margin-top: 32px;
}
.ItemField label{
    font-weight: normal;
    font-size: 16px;
    line-height: 20px;
    color: #010A21;
}
.ItemField input{
    width: 342px;
}

.MuiButton-contained {
    color: #ffffff;
    background-color: #e0e0e0;
}

.ButtonWrapper {
    display: flex;
    align-items: center;
    margin: 20px 0;
}

.ButtonSave {
    margin-right: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100px;
    height: 44px;
    background: #00A896;
    border-radius: 8px;
    color: #ffffff;
    font-weight: 600;
    font-size: 16px;
    padding: 0;
    transition: all .2s;
    border: none;
    cursor: pointer;
}

.ButtonCancel {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 125%;
    color: #6B799D;
    cursor: pointer;
    margin-right: 20px;
}

.Recommendation {
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 125%;
    color: #010A21;
    margin-bottom: 43px;
}