@font-face {
    font-family: 'Open Sans';
    src: local('Open Sans Bold'), url("../../../fonts/open-sans/OpenSans-Bold.ttf") format('truetype');
}

.Container {
    border-bottom: 1px solid #D7DCE8;
    padding-bottom: 10px;
}

.Export {
    font-style: normal;
    font-weight: 600;
    font-size: clamp(1rem, 2vw, 1.5rem);
    line-height: 30px;
    color: #010A21;
}

.Toolbar {
    width: 40px !important;
    height: 40px !important;
}
.IconToolbar {
    color: #ffffff !important;
}
