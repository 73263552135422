@font-face {
    font-family: 'Open Sans LightItalic';
    src: local('Open Sans Light Italic'), url("../../fonts/open-sans/OpenSans-LightItalic.ttf") format('truetype');
}
@font-face {
    font-family: 'Open Sans SemiBold';
    src: local('Open Sans SemiBold'), url("../../fonts/open-sans/OpenSans-SemiBold.ttf") format('truetype');
}
@font-face {
    font-family: 'Open Sans Bold';
    src: local('Open Sans Bold'), url("../../fonts/open-sans/OpenSans-Bold.ttf") format('truetype');
}
@font-face {
    font-family: 'Open Sans Regular';
    src: local('Open Sans Regular'), url("../../fonts/open-sans/OpenSans-Regular.ttf") format('truetype');
}